<template>
  <div>

     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
       </div>
     </div>
     <div class="contents" style="min-height:auto;">
       <div class="mainbox">
          <!-- <el-tabs>
    <el-tab-pane label="供应商系统设置" name="first"></el-tab-pane>
  </el-tabs> -->
  <div class="formTable">
        <el-form ref="goodArr" :model="goodArr" label-position="right" label-width="200px">
          
          <div class="title">订单设置：</div>

          <el-form-item label="订单审核策略" class="strategy">
            <el-radio-group v-model="goodArr.confirmOrderStrategy.type">
              <p style="margin-top:10px"></p>
              <el-radio label="MANUAL">手动审核</el-radio>
              <el-radio label="AUTO">所有订单全部自动审核</el-radio>
              <el-radio label="AUTO_EXCLUDE_NOTED">有备注订单不自动审核</el-radio>
              <el-radio label="GOODS_OVER_NUM_BY_MANUAL">商品数量超限时手动审核，商品数量大于：
                <el-input class="radio-input"
                label="商品超限数量："
                  placeholder="输入商品数量"
                  v-model="goodArr.confirmOrderStrategy.maxGoodsNum"
                  clearable>
                </el-input>
              </el-radio>
            </el-radio-group>            
          </el-form-item>

           <el-form-item label="商品匹配策略">
            <el-radio-group v-model="goodArr.skuMatchStrategy.type">
              <el-radio label="MANUAL">收单后手工匹配</el-radio>
              <el-radio label="AUTO">收单后自动匹配</el-radio>
            </el-radio-group>
          </el-form-item>        


          <div class="title">商品设置：</div>

          <el-form-item label="启用商品品牌：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsBrand">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品产地：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsPlace">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品参数：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsProperties">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用自定义分类：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsCategory">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item label="启用虚拟商品：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsVirtual">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="启用商品简称：">
            <el-radio-group v-model="goodArr.goodsSetting.enableShortName">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品卖点：">
            <el-radio-group v-model="goodArr.goodsSetting.enableRecommend">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品描述：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDescription">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品网盘资料：">
            <el-radio-group v-model="goodArr.goodsSetting.enablePan">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品详情图册：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDetail">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品售后政策：">
            <el-radio-group v-model="goodArr.goodsSetting.enableAfterSale">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用物流属性：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDeliveryExtend">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

        <div class="title">授权设置：</div>
        <el-form-item label="授权码：">
          <el-button @click="showAuthCode">点击查看授权码</el-button>
        </el-form-item>

        </el-form>

        <div class="title">消息设置：</div>
        
        <div class="mention">
            <span class="label">关注公众号，接收订单消息：（二维码请勿分享他人）
            <el-button type="primary" @click="dingyueBtn" style="display: block">订阅消息设置</el-button> 
            </span>
            <span class="qrcode" ><img :src="qrCode" /></span>
        </div>        

       </div>
       </div>   
    </div>
    <msglayer :isShow="isCollapse" @cancalfunc="cancalFuns"></msglayer>
  </div>
</template>

<script>
import msglayer from "@/components/msg";
import { getSupplierSetting, setSupplierSetting, getQrCode, getAuthCode } from "@/api/system";
import backImg from "@/assets/logofang.png";

export default {
  name: 'SystemDetail',
  components: { msglayer },
  data() {
    return {
      goodArr: {
        confirmOrderStrategy: {type:"MANUAL",maxGoodsNum:""},
        skuMatchStrategy: {type:"MANUAL"},
        goodsSetting: {}
      },
      setid: '',
      timeArr: [],
      isCollapse:  false,
      qrCode: backImg,
    }
  },
  created: function () {
   
  },
  methods:{
    showAuthCode() {
        getAuthCode({}).then(res => {
          if(res.code ===200) {
            const rows = ['授权码：' + res.data.code, '有效期：' + res.data.expireMinute + '分钟', "过期时间：" + res.data.expireAt, "请妥善保管，切勿轻易分享给他人" ]
            const message = rows.join(`<br/>`)
            this.$alert(message, '授权码', {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true
            });              
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addfun(){
      var that = this
      var objs= {
        value: ''
      }
      that.timeArr.push(objs);
    },
    delfun(n){
      var that = this
      that.timeArr.splice(n,1)
    },
    getSetData(){
      var that = this
      var datas = {}
      getSupplierSetting(datas).then(res => {
        console.log("getSupplierSetting", res);
        if(res.code ===200) {
          that.setid = res.data.id
          that.goodArr = res.data.supplierSetting          
        }else{
          this.$message.error(res.message)
        }
      })
    }, 
    submitFun(){
      var that = this
      var params = {}
      params.id = that.setid
      params.setting = that.goodArr
      setSupplierSetting(params).then(res => {
        if(res.code === 200){
          that.$message({
            message: '设置成功',
            type: 'success'
          })          
        }else{
          that.$message.error(res.message)
        }
        that.loading = false
      })
    },
    cancalFun(){
      this.$router.go(-1)
    },
    showQrCode() {
      getQrCode({}).then(res => {
        if(res.code === 200) {
          this.qrCode = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    dingyueBtn(){
      this.isCollapse = true
    },
    cancalFuns(e){
      console.log(e)
      this.isCollapse = false
    },
  },
  mounted() {
   this.getSetData()
   this.showQrCode()
  }
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.el-form-item__label{
  font-size: 12px;
}
.el-radio__label {
  font-size: 14px;
}
.timebox{
  width: 300px;
  display: block;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
.strategy .el-radio {
  width:100%;
  margin:00px 30px 10px 0px;
}
.radio-input{
  width:150px;
  height:25px;
}
.title {
  background-color: #f5f6f8;
  line-height: 40px;
  margin:0px 0px 10px 0px;
  font-size:15px;
  padding-left:20px;
  font-weight: bold;
}
.label {
  margin-left: 80px;
  font-size:14px;
  color: #606266;
  float:left;
  padding:  5px 12px 0 0;
  line-height:40px;
}
.qrcode img {
  width:100px;
  height:100px;
}
</style>